.prompterController {
  height: 100px;
}

.floatingInput {
  height: 120px;
  width: 250px;
  background-color: rgb(63, 62, 62);
  position: absolute;
  top: -130px;
  z-index: 9999 !important;
}
.wallakH {
  max-height: calc(100%);
  background-color: #0c0c0c !important;
  /* flex-grow: 1;  */
}

.alrighty [data-contents="true"] > *:not(.promptBlock) {
  filter: brightness(0.3);
}
.prompterArea .DraftEditor-root {
  min-width: 100%;
  max-width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: #0c0c0c !important;
  color: white;
  border-radius: 5px;
}
[data-contents="true"] {
  margin: auto !important;
}
.promptBlock {
  zoom: unset !important;
  height: 100px;
  width: 100%;
  background-color: rgba(40, 40, 41, 0.74);
  font-size: 30px !important;
  margin-bottom: 200px;
}
.prompterArea [data-contents="true"] {
  pointer-events: none;
}

.DraftEditor-root::-webkit-scrollbar {
  z-index: 9999;
  position: absolute;
}
.DraftEditor-root::-webkit-scrollbar {
  z-index: 9999;
  position: absolute;
}

.element-with-bg-color::after {
  content: "\00000A";
  display: block;
  line-break: anywhere;
}
.element-with-bg-color::before {
  content: "\00000A";
  display: block;
  line-break: anywhere;
  /* height: 120px; */
}
