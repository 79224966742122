.toastContainer {
    height: 150px;
    width: 300px;
    background-color: #000000;
    font-size: 20px !important;
    font-weight: 500;
    color: #a5a5a5 !important;
  }
  
  .slide-bottom {
      -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
  .topToast{
    position: fixed;
    margin-top: 0;
    margin-bottom: 0;
    top: 0;
    bottom: 0;
  }
  /* ----------------------------------------------
   * Generated by Animista on 2023-2-5 8:42:52
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */
  
  /**
   * ----------------------------------------
   * animation slide-bottom
   * ----------------------------------------
   */
   @-webkit-keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
      }
    }
    @keyframes slide-bottom {
      0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
      }
      100% {
        -webkit-transform: translateY(5px);
                transform: translateY(5px);
      }
    }
    