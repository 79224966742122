.settingsContainer {
  position: absolute;
  bottom: 100px;
  right: 0;
  min-height: 500px;
  background-color: rgb(17, 17, 17);
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-top-left-radius: 5px;
  z-index: 9999;
  bottom: 75px;
}
input[type="range"]::-webkit-slider-thumb {
  border: 1px solid white;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background: #0b82ef;
  margin-top: -10px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: white;
  border-radius: 1px;
}
.optionsFont {
  font-size: 21px !important;
}
.monitorInputsContainer {
  border-top: 1px solid rgba(255, 255, 255, 0.185);
}
.monitorInputsContainer:nth-child(1) {
  border: none !important;
}

.blueText {
  color: #0b82ef;
}

.optionsParts{
  font-size: 16px !important;
}