.editorContainer {
  width: 95%;
  display: table;
  table-layout: fixed;
  font-size: 20px;
}
.DraftEditor-root {
  width: 100%;
  overflow-y: scroll;
  background-color: #0c0c0c !important;
  color: white;
  border-radius: 5px;
  overflow-x: hidden;
}

.blue {
  background-color: blue;
}


