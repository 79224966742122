.flipVertical {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.flipHorizontal {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  -ms-filter: flipv; /*IE*/
  filter: flipv; /*IE*/
}

.flipBoth {
  transform: scaleX(-1) scaleY(-1) !important;
}

.moka .DraftEditor-root *{
  pointer-events: none;
}