.valueItem {
  height: 25px;
  width: 25px;
}
.recordBtn {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: red;
}

.recordBtnOff {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #818181;
}

.recordBtnLg {
  height: 35px;
  width: 35px;
}
.textTriggerInput:active,
focus {
  border-radius: 0 !important;
  background-color: #111111 !important;
  color: white !important;
}
.textTriggerInput input:not(.searchFunctions) {
  border-radius: 0 !important;
  background-color: #111111 !important;
  color: white !important;
}
.textTriggerInput input::placeholder {
  color: white !important;
}
.textTriggerInput select {
  border-radius: 0 !important;
  background-color: #111111 !important;
  color: white !important;
}

.textTriggerInput .dropup,
.dropend {
  border-radius: 0 !important;
  background-color: #111111 !important;
  color: white !important;
}
.textTriggerInput .functionItem {
  border-radius: 0 !important;
  background-color: #111111;
  color: white !important;
}
*::placeholder {
  color: white !important;
}
/* input:active:not(.editorContainer) ,
:focus{
  color: unset !important;
} */
.innerSubMenu {
  max-height: 500px;
  overflow-y: auto;
}

.darkbg {
  background-color: #000000;
}
.localfuncs{
  max-height: 500px !important;
  overflow-y: auto;
}
.lightdarkbg {
  background-color: #111111;
}
.lightBlue {
  background-color: #00c9ff;
}

.functionContainer {
  min-height: 280px;
}
.table > :not(caption) > * > * {
  padding: 0;
}
.w-35 {
  height: 40px;
}
/* tr{
  height: 40px;
} */

.nonPointer {
  pointer-events: unset;
}

.searchFunctions{
  background-color: green !important;
}