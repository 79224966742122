.actionBarBg {
  background-color: rgb(29, 28, 28);
}

.invert {
  filter: invert(100%);
}
.blueBg {
  background-color: #0b82ef;
}
.editorHeight {
  height: 85vh;
}
.editorWrapper {
  min-height: 100vh;
}
.barItem {
  height: 50px;
  width: 50px;
}
.selectedFont {
   font-family: Arial, sans-serif !important;
}

.closeBtn{
  background-color: white;
  border:1px solid rgb(73, 72, 72)
}
.approveBtn{
  background-color:rgb(73, 72, 72);
  border:1px solid white;
}
.editorWrapper{
  overflow-y: auto !important;
}