body {
  margin: 0;
  font-family: Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(29, 28, 28) !important;
}
*{
  font-family: Arial, sans-serif !important;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(240, 240, 240);
  border-radius: 10px;
  
}
/* html{
  overflow-y: hidden;
} */
.w-1 {
  font-weight: 100;
}
.w-2 {
  font-weight: 200;
}
.w-3 {
  font-weight: 300;
}
.w-4 {
  font-weight: 400;
}
.w-5 {
  font-weight: 500;
}
.w-6 {
  font-weight: 600;
}
.w-7 {
  font-weight: 700;
}

.w-8 {
  font-weight: 800;
}
