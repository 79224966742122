.colorPallete {
  width: 200px;
  position: absolute;
  z-index: 9999 !important;
  top: 100%;
  border-radius: 5px;
  background-color: rgb(102, 96, 96);
}
.colorItem {
  height: 25px;
  width: 25px;
  border-radius: 2px;
}

.plusSize {
  height: 25px;
  width: 25px;
  border: 1px solid rgba(255, 255, 255, 0.123);
}
