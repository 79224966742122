.scriptShortcutContainer {

}
.scriptShortcut {
  min-height: 90%;
}
.scriptsContainer {
  height: 100%;
  overflow-y: auto;
}
.thelist {
  height: 100%;
  overflow: auto;
}
@media screen and (max-width: 1200px) {
  .scriptsContainer {
  }
}
@media screen and (max-height: 850px) {
  .scriptsContainer {
  }
}
