.saveInner {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  height: 100%;
}

.scriptName {
  outline: none !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: none;
  height: 100%;
}
