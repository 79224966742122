.prompterPointer {
  height: 180px !important;
  position: absolute;
  z-index: 8888 !important;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  width: calc(100% - 10px);
  left: 0;
  filter:brightness(1.5) !important;
  backdrop-filter: brightness(300%);
  filter: opacity(1);
}
.pointerImg{
  z-index: 8888 !important;
}
.prompterPointerStripped {
  height: 180px !important;
  position: absolute;
  z-index: 8888 !important;
  width: calc(100% - 10px);
  filter: opacity(1);
  width: 200px;
  left: 0;
}

.dragWrapper{
  height: 180px !important;
}